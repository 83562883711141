.order-container {
  max-width: 600px !important;
}
@media (max-width: 640px) {
  .order-details {
    font-size: 14px;
  }
  .table th,
  .table td {
    padding: 8px; /* Mindre padding för tabellceller */
  }
  .total-amount {
    font-size: 16px;
  }
}

.total-amount {
  right: 10px;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 16px;
}

.table tr {
  cursor: pointer; /* Cursor som pekare */
  transition: background-color 0.3s; /* Mjuk övergång för bakgrundsfärg */
}

.table tr:active {
  background-color: #f0f0f0; /* Bakgrundsfärg vid tryck */
}

/* Ikon för att indikera åtgärd */
.action-icon {
  float: end; /* Positionera till höger */
  transform: rotate(0); /* Standardläge */
  transition: transform 0.3s; /* Animation för rotation */
}

.table tr.expanded .action-icon {
  transform: rotate(90deg); /* Roterad när expanderad */
}

.negative-value {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-thickness: 1px;
}

.product-max-width {
  max-width: 200px !important;
}
.chevron-btn-right {
  padding: 0px;
  margin-top: 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: block;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  background: url("../../../../assets/icons/buttons/chevron-right.svg");
  background-repeat: no-repeat;
}
.chevron-btn-right:active,
.chevron-btn-right:focus {
  box-shadow: none;
  outline: none;
}
.chevron-btn-down {
  padding: 0px;
  margin-top: 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: block;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  background: url("../../../../assets/icons/buttons/chevron-down.svg");
  background-repeat: no-repeat;
}
.chevron-btn-down:active,
.chevron-btn-down:focus {
  box-shadow: none;
  outline: none;
}
