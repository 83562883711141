@import "./colors.scss";

@font-face {
  font-family: "Nobina Headline";

  font-display: "swap";

  font-weight: 400;

  src: url(../../public/fonts/Nobina-Headline-0e7875f596d903fedbd9.woff2)
      format("woff2"),
    url(../../public/fonts/Nobina-Headline-b0ea9fa15d46f13728a6.woff)
      format("woff");
}

@font-face {
  font-family: "Nobina Headline";

  font-display: "swap";

  font-weight: 400;

  font-style: italic;

  src: url(../../public/fonts/Nobina-HeadlineItalic-c309cffcd15720b96636.woff2)
      format("woff2"),
    url(../../public/fonts/Nobina-HeadlineItalic-a94abdf0c29bae29f3a9.woff)
      format("woff");
}

@font-face {
  font-family: "Nobina Text";

  font-display: "swap";

  font-weight: 400;

  src: url(../../public/fonts/Nobina-TextRegular-b0d40cba7de7fadb66e5.woff2)
      format("woff2"),
    url(../../public/fonts/Nobina-TextRegular-9c2917c6623d05c2db5c.woff)
      format("woff");
}

@font-face {
  font-family: "Nobina Text";

  font-display: "swap";

  font-weight: 400;

  font-style: italic;

  src: url(../../public/fonts/Nobina-TextRegularItalic-3d86d741b61938598dcb.woff2)
      format("woff2"),
    url(../../public/fonts/Nobina-TextRegularItalic-0e04da393b36437c222e.woff)
      format("woff");
}

@font-face {
  font-family: "Nobina Text";

  font-display: "swap";

  font-weight: 700;

  src: url(../../public/fonts/Nobina-TextBold-0560cfcc4f1da138e7af.woff2)
      format("woff2"),
    url(../../public/fonts/Nobina-TextBold-c87d7cb952db5853653a.woff)
      format("woff");
}

@font-face {
  font-family: "Nobina Text";

  font-display: "swap";

  font-weight: 700;

  font-style: italic;

  src: url(../../public/fonts/Nobina-TextBoldItalic-119194e37274fa198f0a.woff2)
      format("woff2"),
    url(../../public/fonts/Nobina-TextBoldItalic-a2614a4c2dfc5540d583.woff)
      format("woff");
}

html {
  font-family: "Nobina Text", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

h1,
h2,
h3 {
  font-family: "Nobina Headline", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

span {
  font-family: "Nobina Text";
  font-size: 22px;
  line-height: 30px;
}

p {
  font-family: "Nobina Text";
  font-size: 24px;
  line-height: 26px;
}

/* Links */

a {
  --tw-text-opacity: 1;
  color: rgba(4, 61, 12, var(--tw-text-opacity));
}

a:hover {
  --tw-text-opacity: 1;
  color: rgba(57, 169, 53, var(--tw-text-opacity));
}

a {
  text-decoration: underline;
  font-family: "Nobina Text";
  font-size: 24px;
  line-height: 26px;
}

.span-link {
  --tw-text-opacity: 1;
  color: rgba(4, 61, 12, var(--tw-text-opacity));
}

.span-link:hover {
  --tw-text-opacity: 1;
  color: rgba(57, 169, 53, var(--tw-text-opacity));
}

.span-link {
  text-decoration: underline;
  font-family: "Nobina Text";
  font-size: 24px;
  line-height: 26px;
}

.navlink {
  text-decoration: none;
  :hover {
    --tw-text-opacity: 1;
    color: rgba(57, 169, 53, var(--tw-text-opacity));
  }
}

.text-lightgreen {
  color: $light-green2;
}
.text-bold {
  font-family: NobinaTextBold !important;
}
.text-extra-bold {
  font-weight: 800 !important;
}
.text-sm {
  font-size: 10px !important;
}
.text-m {
  font-size: 16px !important;
}
.text-lg {
  font-size: 20px !important;
}
.text-xl {
  font-size: 24px !important;
}
.text-2xl {
  font-size: 32px !important;
}

.bg-green {
  background-color: #043d0c;
  color: #fff;
}
.bg-lightgreen {
  background-color: $light-green2;
}
.min-height-100 {
  min-height: 100vh;
}
// .container {
//   min-height: 100vh;
//   // position: relative;
// }
// buttons

.text-lang-button {
  --tw-text-opacity: 1;
  color: rgba(4, 61, 12, var(--tw-text-opacity));
}

.text-lang-button:hover {
  --tw-text-opacity: 1;
  color: rgba(57, 169, 53, var(--tw-text-opacity));
}

.text-lang-button {
  text-decoration: underline;
  font-family: "Nobina Text";
  font-size: 20px !important;
  line-height: 26px;
  font-weight: 400 !important;
}
.text-lang-button-selected {
  --tw-text-opacity: 1;
  color: rgba(4, 61, 12, var(--tw-text-opacity));
}

.text-lang-button-selected {
  text-decoration: underline;
  font-family: "Nobina Text";
  font-size: 20px !important;
  line-height: 26px;
  font-weight: 800 !important;
}
.text-lang-button-selected:hover {
  --tw-text-opacity: 1;
  color: rgba(57, 169, 53, var(--tw-text-opacity));
}

.btn-chevron-down {
  float: right;
  text-indent: -9999px;
  width: 30px;
  height: 30px;
  background: url(../assets/icons/buttons/chevron-down.svg) no-repeat top left;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: right;

  &:not(.disable):hover {
    background-color: unset !important;
    color: unset !important;
    border: none;
  }
}

// .btn-close {
//   background: url(../assets/icons/buttons/x-circle.svg) no-repeat top left;
//   background-size: contain;
//   cursor: pointer;
//   height: 20px;
//   width: 20px;
//   background-position: right;
// }
.btn-add {
  background: url(../assets/icons/buttons/add.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  width: 35px;
}
.btn-remove {
  background: url(../assets/icons/buttons/remove.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  width: 35px;
}
.btn-info {
  background: url(../assets/icons/buttons/Btn_info.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 20px;
}
.btn-info-small {
  background: url(../assets/icons/buttons/Btn_info.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 15px;
  width: 20px;
}

.btn-chevron-right {
  float: right;
  text-indent: -9999px;
  width: 30px;
  height: 30px;
  background: url(../assets/icons/buttons/chevron-right.svg) no-repeat top left;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: right;

  &:not(.disable):hover {
    background-color: unset !important;
    color: unset !important;
    border: none;
  }
}
.btn-chevron-up {
  float: right;
  text-indent: -9999px;
  width: 30px;
  height: 30px;
  background: url(../assets/icons/buttons/chevron-up.svg) no-repeat top left;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: right;

  &:not(.disable):hover {
    background-color: unset !important;
    color: unset !important;
    border: none;
  }
}
.btn-chevron-right-white {
  float: right;
  text-indent: -9999px;
  width: 25px;
  height: 30px;
  filter: brightness(0) invert(1);
  background: url(../assets/icons/buttons/chevron-right.svg) no-repeat top left;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: right;

  &:not(.disable):hover {
    background-color: unset !important;
    color: unset !important;
    border: none;
  }
}

.btn-select-lang {
  margin-top: 10px;
  margin-left: px;
  display: block;
  width: 10px;
  height: 10px;
  background: url(../assets/icons/buttons/select_lang.svg) no-repeat;
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    border: none;
    outline: none;
  }
}

.btn {
  --tw-bg-opacity: 1;
  --tw-border-opacity: 1;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.25;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  text-decoration: none;
  white-space: nowrap;
}
.btn-success {
  --tw-bg-opacity: 1;
  background-color: rgba(57, 169, 53, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(57, 169, 53, var(--tw-border-opacity));
  border-radius: 30px;
  border-width: 4px;
  cursor: pointer;

  --tw-text-opacity: 1;
  color: #fff;
  transition-property: background-color, border-color, color, fill, stroke;
}
.btn-secondary {
  border-width: 2px;
  border-style: solid;
  border-color: #043d0c;
  background-color: #f4f4f4;
  color: #043d0c;
}
.btn-danger {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 53, 52, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(215, 53, 52, var(--tw-text-opacity));
  border-radius: 30px;
  cursor: pointer;
  font-weight: 800;
  --tw-text-opacity: 1;
  color: $white;
  transition-property: background-color, border-color, color, fill, stroke;
}

.btn-link {
  background: transparent;
  border: none;
  --tw-bg-opacity: 1;
  cursor: pointer;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.25;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-text-opacity: 1;
  text-decoration: none;
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgba(4, 61, 12, var(--tw-text-opacity));
}

.btn-link:hover {
  --tw-text-opacity: 1;
  color: rgba(57, 169, 53, var(--tw-text-opacity));
}

.btn-fullscreen {
  background-color: transparent !important;
  border-radius: 30px;
  color: #043d0c !important;
  border-color: #043d0c !important;
}

.btn-fullscreen {
  @media (min-width: 768px) {
    display: none;
  }
}

.btn-reciept {
  background-color: transparent !important;
  border-radius: 30px;
  color: #043d0c !important;
  border-color: #043d0c !important;
}

// backgrounds

.bg-light {
  background-color: $special-color1 !important;
}

.bg-lightgray {
  background-color: $special-color1 !important;
}

.bg-info2 {
  background-color: lightgray;
}
.blur-background {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}

// background

// buttons

// border

.border-b {
  border-bottom: 1px solid;
}

.border-green {
  --tw-border-opacity: 1;
  border-color: rgba(57, 169, 53, var(--tw-border-opacity));
}

//border

// forms
.form-group {
  font-family: "Nobina Text";
}

// forms

// table
table {
  font-family: "Nobina Text";
}

//table
.swedish_flag {
  width: 31px;
}
.finnish_flag {
  width: 31px;
}
.english_flag {
  width: 31px;
}
.lang-flag {
  margin-right: 16px;
  width: 31px;
}
.lang-selected {
  border: 2px solid #39a935;
  width: 38px;
}

// icons
.ticket-icon-warning {
  background: url(../assets/icons/ticket/ticket-warning-fill.svg) no-repeat top
    left;

  display: inline-block;
  height: 16px;
  width: 20px;
  fill: red;
  vertical-align: middle;
}
.ticket-icon-danger {
  background: url(../assets/icons/ticket/ticket-danger-fill.svg) no-repeat top
    left;

  display: inline-block;
  height: 16px;
  width: 20px;
  fill: red;
  vertical-align: middle;
}

.ticket-icon-success {
  background: url(../assets/icons/ticket/ticket-success-fill.svg) no-repeat top
    left;

  display: inline-block;
  height: 16px;
  width: 20px;
  fill: red;
  vertical-align: middle;
}
