.top-dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Updated this line */
  z-index: 1000;
}

.top-dialog {
  margin-top: 10px;
  background: white;
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 2;
  min-width: 200px;
  max-width: 375px;
  overflow-wrap: break-word;
}
