.information-dialog {
  display: flex;
  flex-direction: column;
  min-height: 450px !important;
}

.content-container {
  flex-grow: 1;
}
.table.borderless th,
.table.borderless td {
  border: none;
}

.custom-scrollable-list::-webkit-scrollbar {
  width: 10px; /* Bredd på scrollbar */
}

.custom-scrollable-list::-webkit-scrollbar-track {
  background: #f1f1f1; /* Bakgrundsfärg på scrollbaren */
}

.custom-scrollable-list::-webkit-scrollbar-thumb {
  background: #888; /* Färg på scrollbaren när den inte är hoverad */
  border-radius: 5px; /* Rundade hörn på scrollbaren */
}

.custom-scrollable-list::-webkit-scrollbar-thumb:hover {
  background: #555; /* Färg på scrollbaren vid hover */
}

.text-xxs {
  font-size: 14px;
  padding: 4px;
}
