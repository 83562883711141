.navbar-accordion-button {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  font: inherit;
  padding: 0.5rem !important;
  cursor: pointer;
  text-align: left;
  font-size: 20px !important;
  font-weight: 400px !important;
  line-height: 24px !important;
  &:focus {
    outline: none;
  }
}

.navbar-accordion-item-button {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  font-family: "Nobina Text";
  font-size: 24px;
  line-height: 26px;
  cursor: pointer;
  text-align: left;
  &:focus {
    outline: none;
  }
}
