$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$green-800: #043d0b !default;
$black: #000 !default;
$blue: #001e46 !default;
$darkblue: #00063f !default;
$indigo: #3f51b5 !default;
$pink: #e91e63 !default;
$red: #f44336 !default;
$orange: #ff5722 !default;
$yellow: #ffab00 !default;
$green: #043d0c !default;
$light-green: rgba(57, 169, 53, var(--tw-bg-opacity));
$light-green2: #c4db9b;
$teal: #009688 !default;
$cyan: #00bcd4 !default;
$special-color1: #f4e8e4 !default;
$dark-theme-bg: $blue;
$light-theme-bg: $white;
$body-color: $dark-theme-bg;
