.navbar {
  background-color: #043d0c;
  color: #fff;
}
.logo{
  margin-left: 20px;
}

.menu-buttons{
  padding-right: 20px;
}
.js-menu-btn{
  background-color: none;
}
.menu-btn {
  height: 2.75rem;
  width: 2.75rem;
  position: relative;
  right: -20%;
}

.menu-btn__line {
  height: 2px;
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-0.25rem);
  transition: transform 0.25s 0.25s;
}

.menu-btn__line:last-child {
  transform: translateX(-50%) translateY(0.25rem);
}

.menu-btn__line::after {
  content: "";
  background: #39a935;
  /* TODO: Can this hardcoded value be removed? */
  display: block;
  height: 100%;
  width: 100%;
  transform-origin: center center;
  transition: transform 0.25s;
  border-radius: 10px;
}

.menu-btn--active .menu-btn__line {
  transform: translateX(-50%) translateY(50%);
  transition: transform 0.25s;
}

.menu-btn--active .menu-btn__line::after {
  transform: rotate(45deg);
  transition: transform 0.25s 0.25s;
}

.menu-btn--active .menu-btn__line:last-child::after {
  transform: rotate(-45deg);
}
