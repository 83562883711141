@import "../../styles/colors.scss";

.align-right {
  display: flex;
  justify-content: flex-end;
}

.order-table-row {
  width: 100%;
}
