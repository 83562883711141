.swish-container {
  max-width: 400px;
}
.swish-error-container {
  max-width: 400px;
}
.swish-icon-container {
  height: 40px;
  width: 40px;
  padding: 10px;
  display: inline;
  background-color: rgba(57, 169, 53, var(--tw-bg-opacity));
}
.swish-icon {
  height: 30px;
  width: 30px;
}
.swishErrorContainer {
  max-width: 400px;
}
