.ticket-status {
  max-width: 180px !important;
}

.ticket-information {
  max-width: 180px !important;
}

.ticket-header {
  background-color: #cccccc;
  color: black;
}

.ticket-activate-button {
  width: 180px;
  font-size: larger !important;
}
.ticket-confirmation-container {
  display: "flex";
  height: 354px;
}

.ticket-info {
  background: url(../../assets/icons/buttons/info-circle-fill.svg) no-repeat top
    left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.bg-lightblue {
  background-color: lightgrey;
}
.ticket-border {
  border-width: 18px;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-style: solid;

  border-color: blue;
}
.ticket-container {
  max-height: 450px;
  min-height: 450px;
}
.line-override {
  line-height: 10px;
}
