.box-option.selected {
  border: 2px solid #007bff;
  position: relative;
  cursor: pointer;
}

.image-container {
  width: 100%;
  height: 70px;
  margin: 0 auto;
}

// .checkmark {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   height: 25px;
//   width: 25px;
// }
.paymentIcon {
  padding-left: 0px;
  margin-left: 25px;
  height: 50px;
  width: 50px;
  float: left;
}
.paymentText {
  padding-left: 60px !important;
}

.phoneConfirmationNumberContainer {
  max-width: 400px;
}
.container-400 {
  max-width: 400px;
}
.empty-container {
  height: 400px;
}
.error-container {
  height: 400px;
}
