@import "../../styles/colors.scss";

.bookingForm {
  max-width: 400px;
  width: 100%;
  background-color: $special-color1;
}

@media (max-width: 768px) {
  .bookingForm {
    width: 100%;
  }
}

.PhoneInputInput {
  display: block;
  padding: 0.375rem 0.75rem;
  background-color: $white;
  border: 1px solid $white;
  border-radius: 0.375rem;
  color: #212529;
  font-family: inherit;
}

.product-container {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  border-bottom: 1px solid $black;
  padding-bottom: 10px;
}

.details-actions-wrapper {
  display: flex;
  flex-direction: row;
}

.dropdown-menu2 {
  height: 135px;
  max-height: 135px;
  overflow-y: scroll;
  left: 0;
  display: block;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-item {
  cursor: pointer;
  &:hover {
    background-color: black;
  }
}

.table-container {
  border-radius: 10px;
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  max-height: 190px;
  overflow-y: scroll;
  display: block;
  position: relative;
  box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.2);
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(255, 255, 255, 0); /* For Firefox */
}

.table-container::-webkit-scrollbar {
  width: 12px;
  display: block; /* Ensures scrollbar is always visible */
}

.table-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}

.table-header {
  text-align: left;
  font-weight: bold;
  display: table;
  width: 100%;
  table-layout: fixed;
  position: sticky;
  top: 0;
  background-color: rgb(4, 61, 12);
  z-index: 1;
}

.table-body {
  display: block;
  width: 100%;
}

.table-row {
  cursor: pointer;
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative;
}

.table-row:nth-child(even),
.table-row:nth-child(odd) {
  background-color: #ffffff;
}

.table-cell {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  display: table-cell;
  width: 100%;
}

.table-cell:last-child {
  text-align: right;
}

.table-row:hover {
  background-color: #e9ecef;
}

.btn-secondary-departure {
  width: 180px;
}

.product-actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 20px !important;
  float: right;
}

.product-details {
  width: 290px;
  margin-bottom: 20px;
}

.product-price {
  padding-top: 5px;
  padding-left: 10px;
  font-size: 22px !important;
}

.product-description-container {
}

.margin-special {
  padding-top: 20px;
}

.info-icon {
  float: right;
}

.product-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-count {
  width: 40px;
  border: none;
  border-radius: 3px;
  font-size: 22px;
  font-family: "Nobina Text";
}

.custom-dropdown {
  background-color: #eae4e4 !important;
  border: 2px solid #4caf50 !important;
  color: #000;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 10px 40px 10px 10px;
  border-radius: 0;
  z-index: 9999;
}

.custom-dropdown .dropdown-toggle::after {
  margin-left: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 375px) {
  .product-details {
    width: 250px;
  }
}

@media (max-width: 320px) {
  .order-container {
    flex-direction: column;
    align-items: center;
  }
  .product-actions-container {
    width: 100%;
  }
  .product-details {
    width: 250px;
  }
  .product-actions {
    justify-content: space-around;
  }
}

.scroll-text {
  margin-top: 4px;
  text-align: center;
  color: black;
  font-weight: bolder;
  opacity: 0.8;
}

.buy-ticket-header {
  border-bottom: 1px solid black;
}

.clean-button {
  all: unset;
  cursor: pointer;
  padding: 0.5em 1em;
}
