@import "../../styles/colors.scss";

.control-arrow {
}
.control-next {
  background-color: $green !important;
}
.control-prev {
  background-color: $green !important;
}
.control-dots {
  display: none;
}

.carousel-status {
  font-family: "Nobina Text" !important;
  font-size: 24px !important;
  line-height: 26px;
  width: 100%;
  margin-top: 6px;
  text-align: center;
}

.carousel-container {
  width: 280px;
  max-width: 375px;
}
