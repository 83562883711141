@import "../../styles/colors.scss";
.navigation {
  background-color: #043d0c;
  color: #fff;
  position: fixed;
  z-index: 9000;
  width: 100%;
  height: 100%;
  top: 0px;
  margin-top: 50px;
  overflow: scroll;
}

.menu-container {
  padding-top: 15px;
  padding-left: 20px;
  max-width: 762px;
  margin: auto;
  width: 100%;
  height: 100vh;
}

.menu-container-content {
  height: 100vh;
  background-color: rgba(244, 232, 228) !important;
}

.list-item {
  // display: flex;
  a {
    color: $black;
    background-color: transparent;
    text-decoration: none;
    :hover {
      text-decoration: none;
    }
  }
  .border-green {
    display: block;
    color: $black !important;
    width: 100%;
  }
}

.logged-in-as {
  padding-left: 8px;
}
.logged-in {
  padding-left: 16px;
  // margin-top: 16px;
  padding-bottom: 16px;
}

.mypages-container {
  padding: 11px;
}
.mypages-text {
  font-family: "Nobina Text";
  font-size: 20px;
}
