@import "../../styles/colors.scss";

.cookieConsent {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding: 1em !important;
  z-index: 9000 !important;
  background-color: $light-green2;
  overflow: auto;
}

.btn-swedish {
  display: flex;
  align-items: center;
}

.swedish-flag {
  width: 30px;
  height: 30px;
  background: url(../../assets/img/lang/Swedish_flag.svg) no-repeat top left;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: right;
}
.collapsed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.expand-button {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 20px;
}
.btn-cookie {
  background-color: black !important;
  border-radius: 30px;
  color: white !important;
  border-color: gray;
  font-size: 12px !important;
}

.language-button {
  background-color: black;
  border-radius: 30px;
  color: white;
  border: 1px solid black !important;
  font-size: 12px !important;
}

.language-button:hover {
  background-color: #0056b3;
}

.language-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}
