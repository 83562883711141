.paymentPendingContainer {
  max-width: 600px;
}

.center-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.col-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
