@keyframes pulse {
  0%,
  100% {
    background-color: #c4db9b;
  }
  50% {
    background-color: #155724;
  }
}

.pulsating-background {
  animation: pulse 2s infinite;
}

.moving-square {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #155724;
  animation: moveSquare 2s infinite;
  color: transparent;
}

@keyframes moveSquare {
  0%,
  100% {
    left: 26%;
  }
  50% {
    left: 70%;
  }
}

.countdown-text {
  height: 60px;
  font-size: 50px !important;
}
