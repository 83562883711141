.confirmation-dialog {
  display: flex;
  flex-direction: column;
  min-height: 450px !important;
  max-height: 450px !important;
}

.content-container {
  flex-grow: 1;
}
